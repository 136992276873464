/* stylelint-disable string-quotes */
* {
  --primary-background-color: #1215e2;
  --primary-font-family: 'Space Grotesk', sans-serif;
  --secondary-font-family: 'Roboto', sans-serif;
  --global-black: #00011f;

  box-sizing: border-box;
}

html,
body {
  margin: 0 auto;
  font-family: var(--secondary-font-family);
  background-color: var(--primary-background-color);
  overscroll-behavior: none;
  position: relative;
}

h1,
h2 {
  font-family: var(--primary-font-family);
  font-weight: 400;
}

a {
  color: inherit;
}

p {
  font-size: 14px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}

.otp-input input:focus-visible {
  outline: none;
}
